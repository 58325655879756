import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'login',
        meta: { },
        component: () => import("@/views/login.vue")
    },
    {
        path: '/',
        name: 'loggedLayout',
        meta: { },
        component: () => import("@/views/loggedLayout.vue"),
        children: [
            {
                path: 'stepConfig',
                name: 'stepConfig',
                meta: { },
                component: () => import("@/views/stepConfig.vue"),
            },
            {
                path: 'prodotti',
                name: 'prodotti',
                meta: { },
                component: () => import("@/views/prodotti.vue"),
            },
            {
                path: 'presets',
                name: 'presets',
                meta: { },
                component: () => import("@/views/presets.vue"),
            },
            {
                path: 'dictionary',
                name: 'dictionary',
                meta: { },
                component: () => import("@/views/dictionary.vue"),
            }
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
    }
})

router.beforeEach((to, from, next) => {
    if(store.state.beforeNavCbs.length == 0)
        next();
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
})

router.afterEach((to, from) => {
    store.state.afterNavCbs.forEach(x => {
        x(to, from);
    });
    store.state.afterNavCbs = [];
})

export default router
