
import { mixins, Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import store from './store';
import SpinnerContainer from '@/components/spinnerContainer/spinnerContainer.vue';
import { LocalizedClient } from './services/Services';
import { ModalServices } from './services/ModalServices';
import * as OM from '@/Model';

@Options({
    components: {
        SpinnerContainer
    },
})
export default class App extends Vue {

    loaded: boolean = false;

    created(){
        ModalServices.setOpModal(this.$opModal);
        window.addEventListener('scroll', (ev) => {
            store.state.scrollCb(ev);
        });

        Promise.all([
            LocalizedClient.getAll(),
            LocalizedClient.getEnabledLanguages(),
        ])
        .then( x => {
            store.state.consts.localizedValues = x[0];
            store.state.consts.enabledLanguages = x[1];
        }).finally( () => {
            console.log("loaded!!");
            this.loaded = true;
        })
    }

    @Watch("$store.state.showSpinner")
    disableScroll(next, prev){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(next > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }

}
