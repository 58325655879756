import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import './utils';

import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/css/style.css';

import * as DIR from '@/directives';
import FTS from '@/filters';

import modalPlugin from './plugins/modalPlugin';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin)
    .directive('date', DIR.date)
    .directive("localizedPlaceholder", DIR.localizedPlaceholderDirective);
app.config.globalProperties.$filters = FTS;

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $filters: typeof FTS
    }
}

//toastr
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';

toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-bottom-right",
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 4000,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};
window.toastr = toastr;

//input
import MiniSpinner from '@/components/miniSpinner.vue';
app.component('MiniSpinner', MiniSpinner);

import opTable from '@/components/opTable/opTable.vue';
app.component('opTable', opTable);
import localizedText from '@/components/localizedText.vue';
app.component('localizedText', localizedText);
import formField from '@/components/formField.vue';
app.component('formField', formField);
import localizedFormField from '@/components/localizedFormField.vue';
app.component('localizedFormField', localizedFormField);
import fileDropper from '@/components/fileDropper.vue';
app.component('fileDropper', fileDropper);


app.mount('#app');
