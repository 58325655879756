import { createStore } from 'vuex'
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import { UserClient } from '@/services/Services';

let store = {
    state: {
        showSpinner: 0,
        selectedLanguage: "",
        isMobile: false,
        consts: {
            localizedValues: [],
            enabledLanguages: [],
        },
        customerId: <number>null,
        scrollCb: (ev) => {},
        beforeNavCbs: [],
        afterNavCbs: []
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
}

store.state.isMobile = window.innerWidth <= 768;
window.addEventListener('resize', () => {
    _store.state.isMobile = window.innerWidth <= 768;
})
store.state.selectedLanguage = StorageServices.GetLanguage();

let _store = createStore(store);
export default _store