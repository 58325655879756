import moment from 'moment';

export default {

    date(value: any, _format: string, _showTime: boolean) {
        if (value) {
            let format;
            if(!_format){
                format = 'YYYY MMMM DD';
                if(_showTime)
                    format += 'HH:mm:ss';
            } else {
                format = _format;
            }
            return moment(value).format(format);
        }
    },

    timespan(value: string) {
        return value.substr(0, 5);
    },

    currency(value: any, decimals: number) {
        if(!decimals && decimals != 0)
            decimals = 2;
        if (value || value == 0) {
            let val = (value/1).toFixed(decimals).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    },

    underscore(value: string){
        let ris = value;
        ris = ris.replaceAll(", ", "_");
        ris = ris.replaceAll(" ", "_");
        ris = ris.replaceAll("-", "_");
        ris = ris.replaceAll(",", "_");
        ris = ris.replaceAll("'", "_");
        ris = ris.replaceAll(".", "");
        return ris.trim();
    }
    
}