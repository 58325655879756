
import { mixins, Options, Vue } from 'vue-class-component';
import WithModel from '@/mixins/withModel';

@Options({})
export default class FileDropper extends mixins(WithModel) {

    click(){
        (<any>this.$refs.fileInput).click();
    }

    dropImage(ev){
        ev.stopPropagation();
        ev.preventDefault();
        let file = ev.dataTransfer.files[0];
        this.emitModel(file);
    }

    onFileSelect(ev){
        let file = ev.target.files[0];
        this.emitModel(file);
    }
}

