import router from "./router";
import * as OM from "./Model";
import store from "./store";

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized)
        localized = new OM.LocalizedValue();
        
    var enabledLanguages = store.state.consts.enabledLanguages;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
}

export function download(filename, text, type) {
    let blob = new Blob([text], { type })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click();
}

export function getUniversalISOStringDate(input: Date): any {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

    return data.toISOString();
}

export function getUniversalDate(input: any): Date {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

    return data;
}

export function checkLocalizedValue(val: OM.LocalizedValue){
    return Object.values(val.values).every(x => !!x);
}